/** @jsx jsx */
import Icon from "react-icons-kit"
import { Styled, jsx } from "theme-ui"
import useLanguage from "../hooks/useLanguage"

export default function ContactCard({ icon, text, href }) {
  const isAR = useLanguage() === "ar"
  const Wrapper = href ? "a" : "div"
  return (
    <Wrapper
      href={href}
      target="blank"
      rel="noopener noreferrer"
      sx={{
        flex: "1 0 350px",
        cursor: "pointer",
        p: 35,
        textAlign: "center",
        m: 15,
        textDecoration: "none",
        color: "#333",
        bg: "background",
        boxShadow: "0 2px 5px 0px rgba(0,0,0,0.07)",
        ":hover": {
          color: "primary",
          boxShadow: "0 2px 8px rgba(0,0,0,0.07)",
        },
      }}
    >
      <span sx={{ color: "primary" }}>
        <Icon size={35} icon={icon} />
      </span>
      <Styled.h4
        sx={{
          direction: href && "ltr",
          mt: 20,
          fontWeight: isAR ? 700 : 400,
          color: "inherit",
          fontSize: [16, null, 18],
        }}
      >
        {text}
      </Styled.h4>
    </Wrapper>
  )
}
