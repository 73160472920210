import React from "react"
import DottedCard from "../components/dotted-card"
import { Input, Textarea, Button, Alert as ThemeAlert } from "theme-ui"
import { Formik, Form as FForm } from "formik"
import * as Yup from "yup"
import useLanguage from "../hooks/useLanguage"

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "short")
    .max(50, "long")
    .required("Required"),
  "message-title": Yup.string()
    .min(5, "short")
    .required("Required"),
  message: Yup.string()
    .min(5, "short")
    .required("Required"),
  email: Yup.string()
    .email("email")
    .required("Required"),
})

const translations = {
  ar: {
    Required: "الحقل مطلوب!",
    email: "حقل الايميل غير صحيح!",
    short: "النص اقل من المطلوب!",
    long: "النص اطول من المطلوب!",
  },
  en: {
    Required: "Required!",
    email: "Invalid Email!",
    short: "Too Short!",
    long: "Too Long!",
  },
}
const Alert = ({ children, ...props }) => {
  if (!children) return false

  return (
    <ThemeAlert {...props} sx={{ marginBottom: 2 }}>
      {children}
    </ThemeAlert>
  )
}

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function ContactForm() {
  const lang = useLanguage()
  const isAR = lang === "ar"

  const onSubmit = values => {
    fetch("/contact", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      body: encode({ "form-name": "contact", ...values }),
    })
      .then(() => alert("Success!"))
      .catch(error => alert(error))
  }

  return (
    <DottedCard>
      <Formik
        validationSchema={ContactSchema}
        initialValues={{ email: "", password: "" }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, handleChange, values, errors, touched }) =>
          console.log({ errors }) || (
            <FForm
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              {/* You still need to add the hidden input with the form name to your JSX form */}
              <input type="hidden" name="form-name" value="contact" />
              <Input
                name="name"
                onChange={handleChange}
                placeholder={isAR ? "الاسم" : "Your name"}
              />
              <Alert variant="error">{translations[lang][errors.name]}</Alert>
              <Input
                name="email"
                onChange={handleChange}
                placeholder={isAR ? "الايميل" : "Your mail"}
                type="email"
              />
              <Alert variant="error">{translations[lang][errors.email]}</Alert>
              <Input
                name="message-title"
                onChange={handleChange}
                placeholder={isAR ? "عنوان الرسالة" : "Message title"}
              />
              <Alert variant="error">
                {translations[lang][errors["message-title"]]}
              </Alert>
              <Textarea
                onChange={handleChange}
                name="message"
                placeholder={isAR ? "محتوى" : "Message content"}
              />
              <Alert variant="error">
                {translations[lang][errors["message"]]}
              </Alert>

              <Button
                type="submit"
                sx={{ backgroundColor: "primary", width: "100%", p: 10 }}
              >
                {isAR ? "ارسال" : "Sent"}
              </Button>
            </FForm>
          )
        }
      </Formik>
    </DottedCard>
  )
}
