/** @jsx jsx */
import PageLayout from "./page-layout"
import ContactCard from "../components/contact-card"
import { home } from "react-icons-kit/feather/home"
import { phone } from "react-icons-kit/feather/phone"
import { mail } from "react-icons-kit/feather/mail"
import { Flex, Styled, jsx } from "theme-ui"
import Wrapper from "../components/wrapper"
import ContactForm from "../containers/conatact-form"
import { graphql } from "gatsby"
import useLanguage from "../hooks/useLanguage"

export default function ContactUsLayout({ data }) {
  const isAR = useLanguage() === "ar"
  const {
    frontmatter: {
      bg,
      title,
      fillData,
      contactNow,
      contactinfo: { mobile, email, location },
    },
  } = data.markdownRemark
  const breadcrumbs = isAR
    ? [{ title: title, path: "/contact-us" }]
    : [
        {
          title: title,
          path: "/en/contact-us",
        },
      ]

  return (
    <PageLayout
      breadcrumbsStyle={{}}
      mainImage={bg?.childImageSharp?.fluid || {}}
      title={title}
      backgroundColor="#000"
      opacity={0.3}
      maxHeight={287}
      breadcrumbsPaths={breadcrumbs}
    >
      <div
        sx={{
          backgroundColor: "light",
          py: 5,
        }}
      >
        <Wrapper>
          <Flex
            sx={{
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <ContactCard text={location} icon={home} />
            <ContactCard text={mobile} href={`tel://${mobile}`} icon={phone} />
            <ContactCard text={email} href={`mailto://${email}`} icon={mail} />
          </Flex>
        </Wrapper>
      </div>
      <Wrapper>
        <Flex
          sx={{
            justifyContent: "center",
            flexWrap: "wrap",
            py: 5,
          }}
        >
          <div sx={{ width: ["100%", "100%", "50%"] }}>
            <small
              sx={{
                mb: 15,
                color: "primary",
                ":before": {
                  bg: "rgba(0, 0, 0, 0.3)",
                  content: '""',
                  display: "inline-block",
                  position: "relative",
                  top: "-4px",
                  width: 52,
                  height: "2px",
                  marginInlineEnd: "15px",
                },
              }}
            >
              {fillData}
            </small>
            <Styled.h2>{contactNow}</Styled.h2>
            <ContactForm />
          </div>
          <div
            sx={{
              width: ["100%", "100%", "50%"],
              paddingInlineStart: [0, 2],
              iframe: {
                height: 450,
                display: "block",
                border: 0,
                width: "100%",
              },
            }}
          >
            <iframe
              title="خرائط جوجل"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3623.7102724069596!2d46.65007348499958!3d24.73682508411243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ee29e5ee6ad13%3A0xaece30edceca4072!2z2YXYsdmD2LIg2YbZiNix2Kc!5e0!3m2!1sar!2seg!4v1552172035859"
            />
          </div>
        </Flex>
      </Wrapper>
    </PageLayout>
  )
}

export const contactQuery = graphql`
  query($lang: String!) {
    markdownRemark(
      frontmatter: { lang: { eq: $lang } }
      fileAbsolutePath: { regex: "/pages/contact.md/" }
    ) {
      frontmatter {
        title
        fillData
        contactNow
        contactinfo {
          email
          mobile
          location
        }
        bg {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`
